import React from "react";
import { useAuth } from "./contexts/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import EnvironmentBanner from "./components/EnvironmentBanner";
import AppNav from "./navigation/AppNav";
import AuthNav from "./navigation/AuthNav";

export default function App() {
  const { isLoggedIn, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return (
    <Router>
      <EnvironmentBanner />
      {isLoggedIn ? <AppNav /> : <AuthNav />}
    </Router>
  );
}
