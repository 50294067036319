import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../screens/Auth/Login";
import SignUp from "../screens/Auth/SignUp";

const AuthNav = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AuthNav;
