import { createContext, useContext, useState, useEffect } from "react";
import axios from "../utils/axios";

// Initialize context
const APIContext = createContext();

// Export the consumer
export function useAPI() {
  return useContext(APIContext);
}

// Export the provider
export function APIProvider({ children }) {
  const [profile, setProfile] = useState({});

  const getProfile = async () => {
    try {
      const response = await axios.get("/users/profile");
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("No data found in the response");
      }
    } catch (error) {
      throw error?.response?.data?.message || error.message;
    }
  };

  const updateProfile = async (formData = {}) => {
    try {
      const response = await axios.post("/users/profile", formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("No data found in the response");
      }
    } catch (error) {
      throw error?.response?.data?.message || error.message;
    }
  };

  const updateProfilePicture = async (file) => {
    try {
      const formData = new FormData();
      formData.append("photo", file);

      const response = await axios.post("/users/profile-image", formData);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("No data found in the response");
      }
    } catch (error) {
      throw error?.response?.data?.message || error.message;
    }
  };

  useEffect(() => {
    getProfile()
      .then((response) => {
        setProfile(response.user);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <APIContext.Provider
      value={{
        getProfile,
        updateProfile,
        updateProfilePicture,
        profile,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}
