import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../screens/App/Dashboard";
import Profile from "../screens/App/Profile";

const Error404 = () => {
  return <div>404 Error</div>;
};

const AppNav = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default AppNav;
