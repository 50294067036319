import axios from "axios";

let host = process.env.REACT_APP_API_HOST_DEVELOPMENT;

if (process.env.REACT_APP_ENV === "production") {
  host = process.env.REACT_APP_API_HOST_PRODUCTION;
} else if (process.env.REACT_APP_ENV === "staging") {
  host = process.env.REACT_APP_API_HOST_STAGING;
} else {
  host = process.env.REACT_APP_API_HOST_DEVELOPMENT;
}

// axios configuration
const axiosInstance = axios.create({
  baseURL: host + "/v1/",
  withCredentials: true, // Ensure cookies are sent with requests
});

export default axiosInstance;
