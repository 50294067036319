import { createContext, useContext, useState, useEffect } from "react";
import axios from "../utils/axios";

// Initialize context
const AuthContext = createContext();

// Export the consumer
export function useAuth() {
  return useContext(AuthContext);
}

// Export the provider
export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = async (formData = {}) => {
    try {
      const response = await axios.post("/auth/login", formData);
      if (response && response.data) {
        setAccount(response.data.data);
        setIsLoggedIn(true);
        return true;
      } else {
        throw new Error("No data found in the response");
      }
    } catch (error) {
      throw error?.response?.data?.message || error.message;
    }
  };

  const logout = async () => {
    try {
      await axios.post("/auth/logout");
      setIsLoggedIn(false);
      setAccount(null);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const signup = async (formData = {}) => {
    try {
      const response = await axios.post("/auth/signup", formData);
      if (response && response.message) {
        return response.message;
      } else {
        throw new Error("No data found in the response");
      }
    } catch (error) {
      throw error?.response?.data?.message || error.message;
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get("/auth/login");
        if (response.status === 200) {
          // If the server responds that the user is authenticated,
          // update your state accordingly
          setIsLoggedIn(true);
          // You can also set other states if the server sends back user data, for example:
          // setAccount(response.data.user);
          setAccount(response.data.data);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking auth status:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        account,
        login,
        signup,
        logout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
