import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavBar from "../../components/NavBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  content: {
    flex: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
}));

function Dashboard() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <NavBar />
      <Box className={classes.content}>
        <Typography variant="h4">
          Hey! I'm happy to let you know: you are authenticated!
        </Typography>
      </Box>
    </Box>
  );
}

export default Dashboard;
