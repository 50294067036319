// NavBar.js
import React from "react";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  navbar: {
    width: 240,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
}));

function NavBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.navbar}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            className={location.pathname === "/" ? classes.active : ""}
            onClick={() => navigate("/")}
          >
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={location.pathname === "/profile" ? classes.active : ""}
            onClick={() => navigate("/profile")}
          >
            <ListItemText primary="Profile" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={location.pathname === "/settings" ? classes.active : ""}
            onClick={() => navigate("/settings")}
          >
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={location.pathname === "/logout" ? classes.active : ""}
            onClick={() => handleLogout()}
          >
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
}

export default NavBar;
