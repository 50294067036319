export default function EnvironmentBanner() {
  const environment = process.env.REACT_APP_ENV;

  const bannerStyle = {
    position: "fixed",
    top: "25px",
    right: "-50px",
    width: "200px",
    height: "50px",
    lineHeight: "50px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    textAlign: "center",
    transform: "rotate(45deg)",
    zIndex: 1000,
  };

  return (
    environment !== "production" && (
      <div style={bannerStyle}>
        <span>{environment}</span>
      </div>
    )
  );
}
