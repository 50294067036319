import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Avatar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavBar from "../../components/NavBar";
import { useAPI } from "../../contexts/APIContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  content: {
    flex: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
  },
}));

function Profile() {
  const classes = useStyles();
  const { profile, updateProfile, updateProfilePicture } = useAPI();

  // State for each field
  const [firstName, setFirstName] = useState(profile.first_name || "");
  const [lastName, setLastName] = useState(profile.last_name || "");
  const [email, setEmail] = useState(profile.email || "");
  const [phone, setPhone] = useState(profile.phone || "");
  const [profilePic, setProfilePic] = useState(profile.profile_image_url || "");

  // Update state based on profile with fallback values
  useEffect(() => {
    setFirstName(profile.first_name || "");
    setLastName(profile.last_name || "");
    setEmail(profile.email || "");
    setPhone(profile.phone || "");
    setProfilePic(profile.profile_image_url || "");
  }, [profile]);

  const handleSaveChanges = () => {
    const updatedProfile = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
    };
    updateProfile(updatedProfile);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      updateProfilePicture(file).then((updatedUrl) => {
        console.log(updatedUrl);
        if (updatedUrl) {
          setProfilePic(updatedUrl);
        }
      });
    }
  };

  return (
    <Box className={classes.root}>
      <NavBar />
      <Box className={classes.content}>
        <Typography variant="h4">Profile</Typography>
        <Avatar className={classes.avatar} src={profilePic} />
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handlePhotoUpload}
          accept="image/*"
        />
        <label htmlFor="upload-button">
          <Button variant="contained" component="span">
            Upload Photo
          </Button>
        </label>
        <TextField
          className={classes.field}
          label="First Name"
          variant="outlined"
          fullWidth
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          className={classes.field}
          label="Last Name"
          variant="outlined"
          fullWidth
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          className={classes.field}
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className={classes.field}
          label="Phone"
          variant="outlined"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default Profile;
